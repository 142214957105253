import React, { Suspense } from 'react'
import {
	Redirect,
	Route,
	Switch
} from 'react-router-dom'
import { CContainer, CFade } from '@coreui/react'
import Spinner from "react-spinkit";
import routes from '../routes'
import Cookie from 'js-cookie'
import { master } from "../config";

const loading = () => (
	<div className="animated fadeIn" style={{
		position: 'absolute',
		top: '50%',
		left: '50%'
	}} >
		<Spinner name="ball-spin-fade-loader" />
	</div>
);

const TheContent = () => {
	return (
		<main className="c-main p-1">
			<CContainer fluid style={{ paddingLeft: 1, paddingRight: 1 }}>
				<Suspense fallback={loading()}>
					<Switch>
						{routes.map((route, idx) => {
							return route.component && (
							Cookie.get(master) ? (
								<Route
									key={idx}
									path={route.path}
									exact={route.exact}
									name={route.name}
									render={props => (
										<CFade>
											<route.component {...props} />
										</CFade>
									)} />) : <Redirect key={idx} from="/" to="/login" />
							)
						})}
						<Redirect from="/" to="/" />
					</Switch>
				</Suspense>
			</CContainer>
		</main>
	)
}

export default React.memo(TheContent)