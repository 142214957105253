import React, { useEffect } from 'react'

const DmChat = () => {
  useEffect(() => {
    // window.open('https://smartcity.praksa.go.th/chatroom', '_blank')
    window.open('http://103.212.37.242/chatroomadmin/', '_blank')
    // window.open("http://45.136.239.26/chatroom/", "_blank");
    // window.location.replace("http://45.136.239.26/chatroom/", "_blank");
  })

  return <></>
}

export default DmChat
