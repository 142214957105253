import React from 'react'
import ReactDOM from 'react-dom/client'
import 'core-js'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { icons } from './assets/icons'
import { Provider } from 'react-redux'
import store from './store'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import './fonts/Mitr-SemiBold.ttf'
import './fonts/Sarabun-SemiBold.ttf'
import './fonts/Mitr-Regular.ttf'
import './fonts/Sarabun-Regular.ttf'

React.icons = icons

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Provider store={store}>
    <App />
  </Provider>,
)

reportWebVitals()
