import React, { useEffect, useState } from 'react'
import TheFooter from './TheFooter'
import TheHeader from './TheHeader'
import TheSidebar from './TheSidebar'
import TheContent from './TheContent'
import TheChangePassword from './TheChangePassword'
import { NotificationContainer } from 'react-notifications'
import Cookies from 'js-cookie'
import { master, version, SOCKET_URL } from '../config'
import { fetch as fetchFC } from '../fetch'
import moment from 'moment'
import { Helmet } from 'react-helmet'

import { useLocation, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { io } from 'socket.io-client'

const TheLayout = () => {
  const location = useLocation()
  const [title, setTitle] = useState('DEV')

  const [userID, setUserID] = useState('')
  const [admin, setAdmin] = useState(false)
  const [fullName, setFullName] = useState('')
  const [lastLogin, setLastLogin] = useState('')
  const [navigation, setNavigation] = useState([])

  const dispatch = useDispatch()
  const history = useHistory()
  // const navigate = useNavigate()

  useEffect(() => {
    const s1 = io(SOCKET_URL, {
      reconnectionDelayMax: 10000,
      transports: ['websocket'],
      query: {
        user_id: Cookies.get(master),
      },
    })
    if (Cookies.get(master)) {
      dispatch({
        type: 'set',
        socketLogin: s1,
      })
    }
    s1.on('someone-login', (user_id) => {
      // alterWarning({ warning_title: 'SOME ONE HAS LOGIN' })
    })
    s1.on('logout', () => {
      s1.disconnect()
      Cookies.remove(master)
      history.push('/login')
      // navigate('/login')
    })
  }, [])

  const loadData = async () => {
    const data = await fetchFC('get', '/users/profile/' + Cookies.get(master))
    if (data?.success) {
      if (data.data.user_level === 'A') {
        setAdmin(true)
      }

      setUserID(data.data.user_id)
      setFullName(data.data.full_name)

      //set Department_access
      // dispatch({ type: "set", curr_route: data.department_access });

      if (data.data.last_login !== null) {
        // console.log('lastlogin: ', data.last_login)
        setLastLogin(moment(data.data.last_login).utc(+7).format('DD/MM/YY HH:mm:ss'))
      }
    } else {
      history.push('/login')
    }

    const nav = await fetchFC('post', '/menu/nav/', {
      user_id: data?.data?.user_id,
    })
    // console.log('nav', nav)
    if (nav?.success) {
      setNavigation(nav?.data)
    } else {
      history.push('/login')
    }
  }

  useEffect(() => {
    if (Cookies.get(master)) {
      loadData()
    }
  }, [])

  useEffect(() => {
    setTitle('SmartCity ' + location.pathname.split('/')[1].toUpperCase())
  }, [location])

  return (
    <div>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <div className="c-app c-default-layout">
        <TheSidebar navigation={navigation} />
        <div className="c-wrapper">
          <TheHeader userID={userID.substring(0, 1)} fullName={fullName} admin={admin} />
          <div className="c-body">
            <NotificationContainer />
            <TheContent />
          </div>
          <TheFooter lastLogin={lastLogin} version={version} />
        </div>
        <TheChangePassword />
      </div>
    </div>
  )
}

export default TheLayout
