import Axios from 'axios'
import Swal from 'sweetalert2'
import Cookies from 'js-cookie'

import { baseUrl, master, token, remove } from './config'
import { replaceNullObj } from './config'

const alertError = (message, auth = true) => {
  Swal.fire({
    icon: 'error',
    title: 'Error',
    text: message,
    showConfirmButton: true,
    confirmButtonText: 'Ok',
  }).then(() => {
    if (auth === false) {
      remove()
      window.location.replace('/')
    }
  })
}

const fetchLogin = async (params) => {
  try {
    const res = await Axios({
      method: 'post',
      url: baseUrl + '/login',
      data: params,
    })
    return res.data
  } catch (err) {
    alertError(err)
  }
}

const fetch = async (method = 'get', path, params = {}) => {
  let msg = ''
  try {
    const res = await Axios({
      method: method,
      url: baseUrl + path,
      data: params,
      headers: {
        authorization: Cookies.get(master) + ':' + Cookies.get(token),
      },
    })
    // console.log('----> ', res.data)
    msg = res.data.message

    if (res.data.success) {
      return replaceNullObj(res.data) // replace null value to ''
    } else {
      return alertError(res.data.message, true)
    }
  } catch (err) {
    alertError(msg, err)
  }
}

export { fetch, fetchLogin }
