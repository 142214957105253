import Cookies from 'js-cookie'
import Spinner from 'react-spinkit'
import { fetch } from './fetch'

const programs = 'SmartCity'
// const baseUrl = 'http://45.136.239.26:2688/api'
const baseUrl = 'https://smartcity.praksa.go.th/smartcity/api'
// const SOCKET_URL = 'http://45.136.239.26:2688'
const SOCKET_URL = 'http://13.212.254.46:2688'

// const baseUrl = 'http://127.0.0.1:2688/api'
// const SOCKET_URL = 'http://127.0.0.1:2688'
const version = '1.0.2'

const master = programs + 'ID_'
const token = programs + 'TOKEN_'

const remove = () => {
  Cookies.remove(master)
  Cookies.remove(token)
}

const getUserProfile = async () => {
  return await fetch('get', '/users/profile/' + Cookies.get(master))
}

const getPermission = async (menuID) => {
  const params = {
    user_id: Cookies.get(master),
    menu_id: menuID,
  }

  const data = await fetch('post', '/users/permission', params)
  // console.log('data', data.data)
  // if (!data.data) {
  //   return {
  //     open: false,
  //     view: true,
  //     add: true,
  //     edit: true,
  //     delete: true,
  //     copy: true,
  //     print: true,
  //     confirm: true,
  //     other: true,
  //   }
  // }

  return {
    open: !!data.data.action_open,
    view: !!data.data.action_view,
    add: !!data.data.action_add,
    edit: !!data.data.action_edit,
    delete: !!data.data.action_delete,
    copy: !!data.data.action_copy,
    print: !!data.data.action_print,
    confirm: !!data.data.action_confirm,
    other: !!data.data.action_other,
  }
}

const loading = () => (
  <div
    className="animated fadeIn"
    style={{
      position: 'absolute',
      top: '50%',
      left: '50%',
    }}
  >
    <Spinner name="ball-spin-fade-loader" />
  </div>
)

//replace Object.value = null to ''
const replaceNullObj = (obj) => {
  // console.log('active replace null')
  if (Array.isArray(obj)) {
    obj.map((item) => replaceNullObj(item))
  } else if (typeof obj === 'object' && obj !== null) {
    Object.keys(obj).map((item) => {
      if (Array.isArray(obj[item])) obj[item].map((_item) => replaceNullObj(_item))
      if (obj[item] === null || obj[item] === undefined) obj[item] = ''
    })
  }
  return obj
}

export {
  programs,
  baseUrl,
  master,
  token,
  version,
  remove,
  getUserProfile,
  getPermission,
  loading,
  replaceNullObj,
  SOCKET_URL,
}
