import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import './scss/style.scss'
import 'antd/dist/antd.min.css'
import Login from './views/pages/login/Login'
import TheLayout from './containers/TheLayout'
import Cookies from 'js-cookie'
import { programs } from './config'
import './App.css'

const loading = () => (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

function App() {
  return (
    <BrowserRouter basename={'/'}>
      <React.Suspense fallback={loading}>
        <Switch>
          <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />
          <Route path="/" name="Home" render={(props) => <TheLayout {...props} />} />

          {/* <Route path="/" name="Home" render={props => Cookies.get(master) ? <TheLayout {...props} /> : <Login {...props} />} /> */}
        </Switch>
      </React.Suspense>
    </BrowserRouter>
  )
}

export default App
