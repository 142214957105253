import React from 'react'
import DmChat from './views/Chat/DmChat'

// Header Dropdown
const Files = React.lazy(() => import('./views/FileSystem/FileSystem'))
const FileAdd = React.lazy(() => import('./views/FileSystem/FileSystemAdd'))
const FileEdit = React.lazy(() => import('./views/FileSystem/FileSystemEdit'))
const Users = React.lazy(() => import('./views/User/UserSystem'))
const UserAdd = React.lazy(() => import('./views/User/UserSystemAdd'))
const UserEdit = React.lazy(() => import('./views/User/UserSystemEdit'))

//loadiong
const LoadingPage = React.lazy(() => import('./views/Utilities/LoadingPage'))

//master
const MasterNews = React.lazy(() => import('./views/Master/News/News'))
const MasterNewsAdd = React.lazy(() => import('./views/Master/News/NewsAdd'))
const MasterNewsEdit = React.lazy(() => import('./views/Master/News/NewsEdit'))

const MasterCCTV = React.lazy(() => import('./views/Master/CCTV/CCTV'))
const MasterCCTVAdd = React.lazy(() => import('./views/Master/CCTV/CCTVAdd'))
const MasterCCTVEdit = React.lazy(() => import('./views/Master/CCTV/CCTVEdit'))

const MasterAffiliation = React.lazy(() => import('./views/Master/Affiliation/Affiliation'))
const MasterAffiliationAdd = React.lazy(() => import('./views/Master/Affiliation/AffiliationAdd'))
const MasterAffiliationEdit = React.lazy(() => import('./views/Master/Affiliation/AffiliationEdit'))

const MasterPetition = React.lazy(() => import('./views/Master/Petitions/Petitions'))
const MasterPetitionAdd = React.lazy(() => import('./views/Master/Petitions/PetitionsAdd'))
const MasterPetitionEdit = React.lazy(() => import('./views/Master/Petitions/PetitionsEdit'))

const MasterMarkets = React.lazy(() => import('./views/Master/Markets/Markets'))
const MasterMarketsAdd = React.lazy(() => import('./views/Master/Markets/MarketsAdd'))
const MasterMarketsEdit = React.lazy(() => import('./views/Master/Markets/MarketsEdit'))

const Dashboard = React.lazy(() => import('./views/Dashboard/Dashboard'))

const Registration = React.lazy(() => import('./views/Registration/Registration'))

const Chat = React.lazy(() => import('./views/Chat/Chat'))
const QA = React.lazy(() => import('./views/Chat/QA'))
const QAAdd = React.lazy(() => import('./views/Chat/QAAdd'))

const CCTVReport = React.lazy(() => import('./views/Report/CCTVReport'))
const RegistrationReport = React.lazy(() => import('./views/Report/RegistrationReport'))
const GraphPetitionsReport = React.lazy(() => import('./views/Report/GraphPetitionsReport'))
const SOSReport = React.lazy(() => import('./views/Report/SOSReport'))

const E_Market = React.lazy(() => import('./views/E-Market/E_Market'))
const E_MarketEdit = React.lazy(() => import('./views/E-Market/E_MarketEdit'))

const Petitions = React.lazy(() => import('./views/Petitions/Petitions'))
const PetitionsView = React.lazy(() => import('./views/Petitions/PetitionsView'))
const PetitionsEdit = React.lazy(() => import('./views/Petitions/PetitionsEdit'))

const Calendar = React.lazy(() => import('./views/Calendar/Calendar'))
const CalendarAdd = React.lazy(() => import('./views/Calendar/CalendarAdd'))
const CalendarEdit = React.lazy(() => import('./views/Calendar/CalendarEdit'))

const News = React.lazy(() => import('./views/News/News'))
const NewsAdd = React.lazy(() => import('./views/News/NewsAdd'))
const NewsEdit = React.lazy(() => import('./views/News/NewsEdit'))

const SOS = React.lazy(() => import('./views/SOS/SOS'))
const SOSView = React.lazy(() => import('./views/SOS/SOSView'))
const SOSUpdate = React.lazy(() => import('./views/SOS/SOSUpdate'))

const DirectChat = React.lazy(() => import('./views/Chat/DmChat'))
const NoReply = React.lazy(() => import('./views/Chat/NoReply'))

const Question = React.lazy(() => import('./views/Question/Question'))

const routes = [
  { path: '/', exact: true, name: 'หน้าหลัก' },
  { path: '/LoadingPage', exact: true, component: LoadingPage },

  { path: '/file', name: 'ข้อมูลระบบงาน', component: Files, exact: true },
  { path: '/file/add', name: 'เพิ่มระบบงาน', component: FileAdd, exact: true, typeForm: 'add' },
  {
    path: '/file/edit/:id',
    name: 'แก้ไขระบบงาน',
    component: FileEdit,
    exact: true,
    typeForm: 'edit',
  },
  { path: '/user', name: 'ข้อมูลผู้ใช้งาน', component: Users, exact: true },
  { path: '/user/add', name: 'เพิ่มผู้ใช้งาน', component: UserAdd, exact: true },
  { path: '/user/edit/:id', name: 'แก้ไขผู้ใช้งาน', component: UserEdit, exact: true },

  { path: '/master/news', name: 'ข่าวสารและประชาสัมพันธ์', component: MasterNews, exact: true },
  {
    path: '/master/news/add',
    name: 'เพิ่มข่าวสารและประชาสัมพันธ์',
    component: MasterNewsAdd,
    exact: true,
  },
  {
    path: '/master/news/edit/:id',
    name: 'แก้ไขข่าวสารและประชาสัมพันธ์',
    component: MasterNewsEdit,
    exact: true,
  },
  { path: '/master/cctv', name: 'CCTV', component: MasterCCTV, exact: true },
  {
    path: '/master/cctv/add',
    name: 'เพิ่ม CCTV',
    component: MasterCCTVAdd,
    exact: true,
  },
  {
    path: '/master/cctv/edit/:id',
    name: 'แก้ไข CCTV',
    component: MasterCCTVEdit,
    exact: true,
  },
  {
    path: '/master/news/edit/:id',
    name: 'แก้ไขข่าวสารและประชาสัมพันธ์',
    component: MasterNewsEdit,
    exact: true,
  },
  { path: '/master/affiliations', name: 'สังกัด', component: MasterAffiliation, exact: true },
  {
    path: '/master/affiliations/add',
    name: 'เพิ่มสังกัด',
    component: MasterAffiliationAdd,
    exact: true,
  },
  {
    path: '/master/affiliations/edit/:id',
    name: 'แก้ไขสังกัด',
    component: MasterAffiliationEdit,
    exact: true,
  },
  { path: '/master/petitions', name: 'ข้อร้องเรียน', component: MasterPetition, exact: true },
  {
    path: '/master/petitions/add',
    name: 'เพิ่มข้อร้องเรียน',
    component: MasterPetitionAdd,
    exact: true,
  },
  {
    path: '/master/petitions/edit/:id',
    name: 'แก้ไขข้อร้องเรียน',
    component: MasterPetitionEdit,
    exact: true,
  },
  { path: '/master/markets', name: 'ตลาด', component: MasterMarkets, exact: true },
  { path: '/master/markets/add', name: 'เพิ่มตลาด', component: MasterMarketsAdd, exact: true },
  {
    path: '/master/markets/edit/:id',
    name: 'แก้ไขตลาด',
    component: MasterMarketsEdit,
    exact: true,
  },
  {
    path: '/registration',
    name: 'ข้อมูลการลงทะเบียน',
    component: Registration,
    exact: true,
  },
  {
    path: '/chat',
    name: 'ข้อมูล Chat',
    component: Chat,
    exact: true,
  },
  {
    path: '/qa',
    name: 'ตั้งค่าถามตอบ',
    component: QA,
    exact: true,
  },
  {
    path: '/qa/add',
    name: 'เพิ่มการตั้งค่าถามตอบ',
    component: QAAdd,
    exact: true,
  },
  {
    path: '/report/cctv',
    name: 'รายงานการเข้าใช้งานกล้อง CCTV',
    component: CCTVReport,
    exact: true,
  },
  {
    path: '/report/registration',
    name: 'รายงานข้อมูลการลงทะเบียน',
    component: RegistrationReport,
    exact: true,
  },
  {
    path: '/report/petition',
    name: 'รายงานข้อมูลแจ้งข้อร้องเรียน',
    component: GraphPetitionsReport,
    exact: true,
  },
  {
    path: '/report/sos',
    name: 'รายงานข้อมูล SOS',
    component: SOSReport,
    exact: true,
  },
  {
    path: '/e-market',
    name: 'ตลาดออนไลน์',
    component: E_Market,
    exact: true,
  },
  {
    path: '/e-market/edit/:id',
    name: 'แก้ไขตลาดออนไลน์',
    component: E_MarketEdit,
    exact: true,
  },
  {
    path: '/petitions',
    name: 'แจ้งข้อร้องเรียน',
    component: Petitions,
    exact: true,
  },
  {
    path: '/petitions/view/:id',
    name: 'มุมมองข้อร้องเรียน',
    component: PetitionsView,
    exact: true,
  },
  {
    path: '/petitions/update/:id',
    name: 'อัพเดทแจ้งข้อร้องเรียน',
    component: PetitionsEdit,
    exact: true,
  },
  {
    path: '/calendar',
    name: 'ปฏิทินกิจกรรม',
    component: Calendar,
    exact: true,
  },
  {
    path: '/calendar/add',
    name: 'เพิ่มปฏิทินกิจกรรม',
    component: CalendarAdd,
    exact: true,
  },
  {
    path: '/calendar/edit/:id',
    name: 'แก้ไขปฏิทินกิจกรรม',
    component: CalendarEdit,
    exact: true,
  },
  {
    path: '/news',
    name: 'ข่าวสารและประชาสัมพันธ์',
    component: News,
    exact: true,
  },
  {
    path: '/news/add',
    name: 'เพิ่มข่าวสารและประชาสัมพันธ์',
    component: NewsAdd,
    exact: true,
  },
  {
    path: '/news/edit/:id',
    name: 'แก้ไขข่าวสารและประชาสัมพันธ์',
    component: NewsEdit,
    exact: true,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    exact: true,
  },
  {
    path: '/sos',
    name: 'แจ้งเหตุฉุกเฉิน SOS',
    component: SOS,
    exact: true,
  },
  {
    path: '/sos/view/:id',
    name: 'มุมมองแจ้งเหตุฉุกเฉิน SOS',
    component: SOSView,
    exact: true,
  },
  {
    path: '/sos/update/:id',
    name: 'อัพเดทแจ้งเหตุฉุกเฉิน SOS',
    component: SOSUpdate,
    exact: true,
  },
  {
    path: '/dmchat',
    name: 'Chat ติดต่อเจ้าหน้าที่',
    component: DirectChat,
    exact: true,
  },
  {
    path: '/noreply',
    name: 'Chat ที่ยังไม่ได้ตอบกลับ',
    component: NoReply,
    exact: true,
  },
  {
    path: '/question',
    name: 'สอบถามเพิ่มเติม',
    component: Question,
    exact: true,
  },
]

export default routes
